var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "보호구 입고 이력",
                tableId: "table",
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                merge: _vm.grid.merge,
                isFullScreen: false,
                columnSetting: false,
                filtering: false,
                usePaging: false,
                isExcelDown: false,
                gridHeight: "600px",
                selection: "multiple",
                rowKey: "safetySuppliesGiveId",
                plantCd: _vm.data.plantCd,
              },
            },
            [
              _c("template", { slot: "table-button" }, [
                _c(
                  "div",
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "추가", icon: "add" },
                              on: { btnClicked: _vm.addrowGive },
                            })
                          : _vm._e(),
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "삭제", icon: "remove" },
                              on: { btnClicked: _vm.removeGive },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
        [
          _c("c-table", {
            ref: "table",
            attrs: {
              title: "안전모 수령인 목록",
              tableId: "table",
              columns: _vm.grid2.columns,
              data: _vm.grid2.data,
              merge: _vm.grid2.merge,
              isFullScreen: false,
              columnSetting: false,
              filtering: false,
              usePaging: false,
              isExcelDown: false,
              editable: false,
              gridHeight: "600px",
              selection: "multiple",
              rowKey: "safetySuppliesGiveId",
              plantCd: _vm.data.plantCd,
            },
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }