<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
      <c-table
        ref="table"
        title="보호구 입고 이력"
        tableId="table"
        :columns="grid.columns"
        :data="grid.data"
        :merge="grid.merge"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :isExcelDown="false"
        gridHeight="600px"
        selection="multiple"
        rowKey="safetySuppliesGiveId"
        :plantCd="data.plantCd"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <div>
            <q-btn-group outline>
              <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addrowGive" />
              <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="removeGive" />
            </q-btn-group>
          </div>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <c-table
        ref="table"
        title="안전모 수령인 목록"
        tableId="table"
        :columns="grid2.columns"
        :data="grid2.data"
        :merge="grid2.merge"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :isExcelDown="false"
        :editable="false"
        gridHeight="600px"
        selection="multiple"
        rowKey="safetySuppliesGiveId"
        :plantCd="data.plantCd"
      >
      
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'safety-gear-receive-status-user',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        safetySuppliesGiveId: '',
        plantCd: '',
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      data: {
        plantCd: null,
        itemName: '안전모'
      },
      grid: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '입고일',
            style: 'width:120px',
            align: 'left',
            type: 'date',
            setHeader: true,
            sortable: false,
          },
        ],
        data: [],
      },
      grid2: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '출고일',
            style: 'width:120px',
            align: 'left',
            type: 'date',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '출고수량',
            style: 'width:100px',
            align: 'right',
            type: 'number',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '수령인',
            style: 'width:100px',
            align: 'right',
            type: 'custom',
            setHeader: true,
            sortable: false,
          },
        ],
        data: [],
      },
      typeItems: [
        { code: 'Y', codeName: '전체' },
        { code: 'N', codeName: '일자별' },
      ],
      searchParam: {
        plantCd: null,
        giveDt: '',
        safetySuppliesGiveId: '',
      },
      editable: true,
      disable: false,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          col1: '2022-03-08',
          col2: 'V000000001',
          col3: '10',
          col4: 100000,
          col5: '1,000,000원',
          col6: '',
        },
        {
          col1: '2022-05-12',
          col2: '',
          col3: '20',
          col4: 200000,
          col5: '4,000,000원',
          col6: '',
        },
        {
          col1: '2022-07-25',
          col2: '',
          col3: '15',
          col4: 200000,
          col5: '3,000,000원',
          col6: '',
        },
        {
          col1: '2022-09-25',
          col2: '',
          col3: '12',
          col4: 200000,
          col5: '2,400,000원',
          col6: '',
        },
        {
          col1: '2022-12-18',
          col2: '',
          col3: '15',
          col4: 200000,
          col5: '3,000,000원',
          col6: '',
        },
      ];
    },
    apprRequest() { 
    },
    completeRequest() { 
    },
    addrowGive() {
      this.grid.data.splice(0, 0, {

      })
    },
    saveRequest() {
    },
    removeGive() {
    },
  },
};
</script>
